import React, { useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import "./Mobile.css";

function Mobile({ data }) {
  const pageLength = (data.length - 1);
  const [lang, setLang] = useState("Emilese");

  const replay = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <section className="cover">
        <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}>
          <h1 className="title">Runaway Food</h1>
          <img className="intro-img" src="img/3.1.png" alt="Runaway Food" />
          <h2 className="author">By Emily Margaret Moore</h2>
          <h3 className="illustrator">
            Illustrated by <a href="https://rhymeswithhell.studio" target="_blank" rel="noreferrer">Elle Levy</a>
          </h3>
        </ScrollAnimation>
      </section>
      <section className="main">
        {data.map((obj, index) => {
          let isLast = index === pageLength;

          return (
            <React.Fragment key={index}>
              <ScrollAnimation animateIn="fadeIn">
                <img className="sidekick" src={`img/${obj.img.sidekick}`} alt="" />
                <p className="copy" dangerouslySetInnerHTML={ {__html: obj.copy[lang]} } />
                <div className="lang-buttons">
                  <span className={lang === "Emilese" ? "is-active" : ""} onClick={() => setLang("Emilese")}>Emilese</span>
                  <span className={lang === "English" ? "is-active" : ""} onClick={() => setLang("English")}>English</span>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" animateOut={isLast ? "fadeOut" : ""}>
                <img className="hero" src={`img/${obj.img.hero}`} alt="" />
              </ScrollAnimation>
            </React.Fragment>
          )
        })}
      </section>
      <section className="end">
        <ScrollAnimation animateIn="fadeIn">
          <h4 className="end-text">The<br />End</h4>
          <img className="end-img" src="img/5.1.png" alt="The End" />
          <span className="replay" onClick={() => replay()}>Again <img src="ico/replay.svg" alt="replay" /></span>
        </ScrollAnimation>
      </section>
    </>
  )
}


export default Mobile;
