import React, { useState } from "react";
import useKeyPress from './hooks/useKeyPress';
import "./Desktop.css";

import arrowLeft from './img/arrow-left.svg';
import arrowRight from './img/arrow-right.svg';

function Desktop({ data }) {
  const bounds = [0, (data.length + 1)];
  const [page, setPage] = useState(0);
  const [lang, setLang] = useState("Emilese");

  useKeyPress('ArrowLeft', page, () => {
    let prevPage = page > bounds[0] ? page - 1 : bounds[0];
    setPage(prevPage);
  });

  useKeyPress('ArrowRight', page, () => {
    const nextPage = page < bounds[1] ? page + 1 : bounds[1];
    setPage(nextPage);
  });

  return (
    <>
      <div className={`container-d cover ${page === 0 ? " is-active" : ""}`}>
        <h1 className="title-d">Runaway Food</h1>
        <img className="intro-img-d" src="img/3.1.png" alt="Runaway Food" />
        <div className="intro-controls-d">
          <img className="left-d" src={arrowLeft} alt="arrow left" />
          <h5>Turn page with arrow keys</h5>
          <img className="right-d" src={arrowRight} alt="arrow right" />
        </div>
        <h2 className="author-d">By Emily Margaret Moore</h2>
        <h3 className="illustrator-d">
          Illustrated by <a href="https://rhymeswithhell.studio" target="_blank" rel="noreferrer">Elle Levy</a>
        </h3>
      </div>

      {data.map((obj, i) => {
        const { copy, img } = obj;
        return (
          <div key={i} className={`container-d ${page === (i + 1) ? " is-active" : ""}`}>
            <div className="inner-left-d">
              <p className="copy-d" dangerouslySetInnerHTML={ {__html: copy[lang]} } />
              <div className="lang-buttons-d">
                <span className={lang === "Emilese" ? "is-active" : ""} onClick={() => setLang("Emilese")}>Emilese</span>
                <span className={lang === "English" ? "is-active" : ""} onClick={() => setLang("English")}>English</span>
              </div>
              <img className="sidekick-d" src={`img/${img.sidekick}`} alt="" />
            </div>
            <img className="hero-d" src={`img/${img.hero}`} alt="" />
          </div>
        )
      })}

      <div className={`container-d end ${page === 8 ? " is-active" : ""}`}>
        <h4 className="end-text">The<br />End</h4>
        <img className="end-img" src="img/5.1.png" alt="The End" />
        <span className="replay" onClick={() => setPage(0)}>
          Again <img src="ico/replay.svg" alt="replay" />
        </span>
      </div>
    </>
  );
}

export default Desktop;
