import React from "react";
import Mobile from './Mobile';
import Desktop from './Desktop';
import data from './json/data.json';
import useWindowSize from './hooks/useWindowSize.js'
import './App.css';


function App() {
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width > 900;

  if (isDesktop && windowSize.width) {
    return (
      <div className="desktop">
        <Desktop data={data} />
      </div>
    )
  } else if (windowSize.width) {
    return (
      <div className="mobile">
        <Mobile data={data} />
      </div>
    )
  } else {
    return null;
  }
}

export default App;
